<template>
  <div class="content_body Weixin">
    <el-card shadow="always" class="des_card">
      <div slot="header" >
        <span>商家公众号</span>
         <el-button style="float: right; padding: 3px 0" type="text" @click="authorizationClick">{{info?'解除授权':"立即授权"}} </el-button>
      </div>
      <div class="font_13 color_666 ">
        授权商家公众号后，顾客可在微信内收到商家公众号的消息推送； <br>
        预约、开单、购卡等各种场景下，顾客可收到微信模板消息提醒；<br>
      </div>
    </el-card>
    <el-card v-if="info" class="des_card">
      <el-form :model="info" class="WeixinInfo" label-width="100px">
        <el-form-item label="公众号昵称：">{{info.authorizerInfo.nickName}}</el-form-item>
        <el-form-item label="公众号类型：">{{getServiceTypeInfo()}}</el-form-item>
        <el-form-item label="二维码：">
          <el-image :src="info.authorizerInfo.qrcodeUrl" style="width:150px;height:150px" :preview-src-list="[info.authorizerInfo.qrcodeUrl]"></el-image>
        </el-form-item>
      </el-form>
    </el-card>
     <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" >
      <span>请在新窗口中完成公众号授权</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="authorizationError">授权失败，重试</el-button>
        <el-button type="primary" @click="authorizationSucceed">已成功设置</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import API from "@/api/Marketing/App/WeiXinOpen";

export default {
 name: 'WeiXin',
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      timer:"",
      info:null,
      dialogVisible:false,

    }
  },
  /**  方法集合  */
  methods: {
    /**  服务状态  */
    getServiceTypeInfo(){
      let that = this
      switch (that.info.authorizerInfo.serviceTypeInfo) {
        case 0:
          return "订阅号"
        case 1:
          return "订阅号"
        case 2:
          return "服务号"
      }
    },
    /**  点击授权--解除授权  */
    authorizationClick(){
      let that = this
      if(that.info){
        that.OffiaccountRemoveBind();
      }
      else{
        that.authorizationOffiaccountComponentNet();
      }
    },
    /**  跳转连接   */
    authorizationOffiaccountComponentNet(){
      let that = this
      API.offiaccountComponent().then((res)=>{
        if (res.StateCode == 200) {
          let url = res.Data.url;
          window.open(url)
          that.dialogVisible = true;
          } else {
            that.$message.error({
              message:"操作失败",
              duration:2000,
            })
          }
      })
    },
    /** 授权失败重试   */
    authorizationError(){
      let that = this
      that.authorizationOffiaccountComponentNet()
    
    },
    /**  授权成功请求信息  */
    authorizationSucceed(){
      let that = this
      that.getOffiaccountAuthorizerInfo();
    },
    /**  解除公众号授权  */
    OffiaccountRemoveBind(){
      var that = this;
      that.$confirm('解除授权微信号，会造成当前店铺的重要信息丢失（包括图文素材、自动回复、自定义菜单等），请谨慎操作','提示',{
        confirmButtonText: '解除授权',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.setOffiaccountRemoveBind();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });          
      });
     
    },
    /**  解除授权请求  */
    setOffiaccountRemoveBind(){
      let that = this
     API.setOffiaccountRemoveBind()
        .then((res) => {
          if (res.StateCode == 200) {
            this.$message.error({
              message: '已解除授权',
              duration:2000,
            });
            that.info = null
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.dialogVisible = false;
        });
    
    },


    /**  获取授权信息  */
    getOffiaccountAuthorizerInfo(){
      var that = this;
      API.getOffiaccountAuthorizerInfo()
        .then((res) => {
          if (res.StateCode == 200) {
            that.info = res.Data;
          } else {
            // that.$message.error({
            //   message: res.Message,
            //   duration: 2000,
            // });
          }
        })
        .finally(function () {
          that.dialogVisible = false;
        });
    
    },
    
  },
  /**  实例被挂载后调用  */
  mounted() {
    this.getOffiaccountAuthorizerInfo();
  },
}
</script>

<style  lang="scss">
.Weixin{
  .des_card{
    width:500px;
    margin-bottom: 30px;
  }
  .WeixinInfo{
    width:500px;
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }
}



</style>
