/**
 * Created by preference on 2021/02/01
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /**  小程序立即授权 */
  miniprogramComponent:params =>{
    return API.POST('api/open/miniprogramComponentloginpage', params)
  },
  /**  公众号立即授权 */
  offiaccountComponent:params=>{
    return API.POST('api/open/offiaccountComponentloginpage', params)
  },
  /** 公众号绑定成功查询信息  */
  getOffiaccountAuthorizerInfo: params => {
    return API.POST('api/open/offiaccountAuthorizerInfo', params)
  },
  /** 公众号绑定成功查询信息  */
  getMiniprogramAuthorizerInfo: params => {
    return API.POST('api/open/miniprogramAuthorizerInfo', params)
  },
  /** 公众号绑定成功查询信息  */
  setOffiaccountRemoveBind: params => {
    return API.POST('api/open/offiaccountRemoveBind', params)
  },
  /** 公众号绑定成功查询信息  */
  setMiniprogramRemoveBind: params => {
    return API.POST('api/open/miniprogramRemoveBind', params)
  },

}